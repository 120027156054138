<template>
    <Button label="Show" class="opacity-0" icon="pi pi-external-link" @click="visible = true" />

    <Dialog v-model:visible="visible" modal header="Powered Goose team" :style="{ width: '50vw' }">
        <section class="surface-ground text-center px-0 sm:px-4 py-8 md:px-6 lg:px-8">
            <div class="flex justify-content-center text-center mb-4 gap-2"><img
                    src="image/logo.png" height="64"></div>
            <div class="flex justify-content-center">
                <h2 class="px-2 md:px-6 text-900 text-2xl font-medium line-height-3 mb-0" style="max-width: 1350px;">
                    Разработано в 2023 году командой Goose IT team ”
                </h2>
                <p>
                    
                </p>
            </div><img  src="image/goose.jpg" width="64" height="64" class="mt-4 border-circle">
            <p class="text-900 font-semibold text-xl line-height-3 text-center">gooseitteam@gmail.com</p>
            <p class="text-600 font-normal line-height-1 mt-2 text-md text-center">Software Engineers</p>
        </section>
    </Dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
        }
    }
}
</script>
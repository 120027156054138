<template>
    <div class=" px-4 pt-5 pb-3 md:px-6 lg:px-8">
        <div class="">
            <div class="grid">
                <div class="col-12 lg:col-4 p-3 py-1">
                    <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                        
                        <div class="bg-orange-100 inline-flex align-items-center justify-content-center mr-3"
                            style="width: 48px; height: 48px; border-radius: 10px;">
                            <i class="pi pi-wallet text-orange-600 text-3xl"></i>
                            </div>
                        <div><span class="text-900 text-xl font-medium mb-2">БЕЗ КОМИССИИ </span>
                            <p class="mt-1 mb-0 text-600 font-medium text-sm">-</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-4 p-3 py-1">
                    <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                        <div class="bg-pink-100 inline-flex align-items-center justify-content-center mr-3"
                            style="width: 48px; height: 48px; border-radius: 10px;">
                            <i class="pi pi-users text-pink-600 text-3xl"></i>
                            </div>
                        <div><span class="text-900 text-xl font-medium mb-2">ПОДКЛЮЧ </span>
                            <p class="mt-1 mb-0 text-600 font-medium text-sm">С полным сопровождением, до получения ключей и документов </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-4 p-3 pt-1">
                    <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                        <div class="bg-teal-100 inline-flex align-items-center justify-content-center mr-3"
                            style="width: 48px; height: 48px; border-radius: 10px;">
                            <i class="pi pi-shield text-teal-600 text-3xl"></i>
                        </div>
                        <div><span class="text-900 text-xl font-medium mb-2">ТОЛЬКО ЛИКВИДНЫЕ ОБЪЕКТЫ</span>
                            <p class="mt-1 mb-0 text-600 font-medium text-sm">От 30% с Перепродажи И 10% от аренды</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><!---->
</template>
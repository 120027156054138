<template>
    <div id="links_btn_right">


        <SpeedDial :model="items" :radius="80" type="semi-circle" direction="left"
            :style="{ top: 'calc(50% - 2rem)', right: 0 }" />

    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    label: 'Instagram',
                    icon: 'pi pi-instagram',
                    command: () => {
                        window.location.href = 'https://instagram.com/gaukhara_bolat?igshid=MzRlODBiNWFlZA==';
                    }
                },
                {
                    label: 'Telegram',
                    icon: 'pi pi-telegram',
                    command: () => {
                        window.location.href = 'https://vuejs.org/';
                    }
                },
                {
                    label: 'WhatsApp',
                    icon: 'pi pi-whatsapp',
                    command: () => {
                        window.location.href = ' https://wa.me/905340771178';
                        //TODO: 
                    }
                },
                // {
                //     label: 'YouTube',
                //     icon: 'pi pi-youtube',
                //     command: () => {
                //         window.location.href = 'https://vuejs.org/';
                //     }
                // },
                // {
                //     label: 'Gmail',
                //     icon: 'pi pi-google',
                //     command: () => {
                //         window.location.href = 'https://vuejs.org/';
                //     }
                // },

            ]
        }
    }
}
</script>

<style>
#links_btn_right{
    position: fixed;
    top: 50vh; 
    right: 0;
    z-index: 100;
}
</style>
<template>
    <div>
        <div>
            <video id="main_card_video" autoplay muted loop>
                <source src="/video/sea.mp4" type="video/mp4">
            </video>
        </div>

        <div class="block-content mt-4">
            <div class="">
                <div class="surface px-4 md:px-6 lg:px-8">
                    <!-- <div class="font-bold text-5xl text-900 mb-5 text-center">Featured Articles</div> -->
                    <div class=" grid lg:flex lg:align-items-stretch">

                        <div class="lg:flex col-12 lg:col-6 ">
                            <Card class=" white_bg glass shadow-6 "
                                style="background-color: rgba(red, green, blue, alpha);">
                                <template #title>
                                    <div class="flex flex-row-reverse flex-wrap">
                                        <img alt="logo" src="/image/logo.png" height="64" class="mx-2" />

                                    </div>
                                    <!-- <span class="text-800 text-mute ml-auto text-right">Онлайн конференция</span> -->
                                </template>
                                <template #content>
                                    <section class="py-5 px-3">

                                        <span class="text-3xl md:text-6xl text-cyan-700 base_color font-bold p-1">БЕЗОПАНОЕ</span>

                                        <span class="block text-2xl md:text-6xl  font-bold mb-1">
                                            <span class="text-2xl md:text-6xl ">Инвестирование в</span>
                                            Зарубежную Недвижимость
                                            <span class="text-2xl md:text-6xl text-cyan-700 base_color">от $98'000</span>
                                            с заработком:
                                        </span>

                                    </section>
                                </template>
                            </Card>
                        </div>

                        
                        <ContactForm/>
                    </div>
                </div>
            </div><!---->
        </div>

    </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
export default {
  name: 'MainCard',
  components: {
    ContactForm,
  }
}
</script>

<style>
#main_card_video {
    min-width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -10;
}
</style>


<template>
  <LinkBtn/>

  <router-view/>
</template>

<script>
import LinkBtn from '@/components/LinkBtn.vue'
export default {
  components: {
    LinkBtn,
  }
}
</script>


<style>
body {
  /* padding: 4.5rem; */
  /* margin: 0; */
  /* font-family: 'Inter', sans-serif; */
}

#links_btn{
  position: fixed;
}


</style>


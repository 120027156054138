<template>
    <div class="px-4 pt-5 md:px-6 lg:px-8">
        <div>
            <div class="font-bold text-3xl text-cyan-700  mb-3 surface-card shadow-2 p-3 border-round">На конференций мы разберем:</div>

            <div class="mb-5 flex">
                <div class="flex flex-column align-items-center" style="width: 2rem;"><span
                        class="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle p-2"><i
                            class="pi pi-percentage "></i></span>
                    <div class="h-full bg-blue-500" style="width: 2px; min-height: 4rem;"></div>
                </div>
                <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                    <div class="mb-3"><span class="text-900 text-blue-700 font-medium inline-block mr-3">1. «40% ЗА 6 МЕСЯЦЕВ»</span><span
                            class="text-500 text-sm"></span></div>
                    <div class="line-height-3 text-700 mb-3"> 
                        КАКОЙ ОБЪЕКТ ДАСТ БОЛЕЕ 46% ЗА 6 МЕСЯЦЕВ ОТ ПЕРЕПРОДАЖИ?    
                    </div>
                    <div class="text-500 flex align-items-center gap-4">
                        <div class="flex align-items-center gap-1">
                            <i class="pi pi-clock"></i>
                            <span class="mr-3">
                                5 мин
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-5 flex">
                <div class="flex flex-column align-items-center" style="width: 2rem;"><span
                        class="bg-orange-500 text-0 flex align-items-center justify-content-center border-circle p-2"><i
                            class="pi pi-chart-line"></i></span>
                    <div class="h-full bg-orange-500" style="width: 2px; min-height: 4rem;"></div>
                </div>
                <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                    <div class="mb-3"><span class="text-900 text-orange-500 font-medium inline-block mr-3">2. Стратегии</span><span
                            class="text-500 text-sm"></span></div>
                    <div class="line-height-3 text-700 mb-3"> 
                        3 стратегий инвестирования в цифрах и кейсы  
                    </div>
                    <div class="text-500 flex align-items-center gap-4">
                        <div class="flex align-items-center gap-1">
                            <i class="pi pi-clock"></i>
                            <span class="mr-3">
                                10 мин
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-5 flex">
                <div class="flex flex-column align-items-center" style="width: 2rem;"><span
                        class="bg-purple-500 text-0 flex align-items-center justify-content-center border-circle p-2"><i
                            class="pi pi-briefcase"></i></span>
                    <div class="h-full bg-purple-500" style="width: 2px; min-height: 4rem;"></div>
                </div>
                <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                    <div class="mb-3"><span class="text-900 text-purple-500 font-medium inline-block mr-3">3. Налоги и законы</span><span
                            class="text-500 text-sm"></span></div>
                    <div class="line-height-3 text-700 mb-3"> 
                        Налоги, законы и юридический процесс  приобретения недвижимости в каждой страны  
                    </div>
                    <div class="text-500 flex align-items-center gap-4">
                        <div class="flex align-items-center gap-1">
                            <i class="pi pi-clock"></i>
                            <span class="mr-3">
                                10 мин
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-5 flex">
                <div class="flex flex-column align-items-center" style="width: 2rem;"><span
                        class="bg-green-500 text-0 flex align-items-center justify-content-center border-circle p-2"><i
                            class="pi pi-compass text-xl"></i></span>
                    <div class="h-full bg-green-500" style="width: 2px; min-height: 4rem;"></div>
                </div>
                <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                    <div class="mb-3"><span class="text-900 text-green-700 font-medium inline-block mr-3">4. Камни</span><span
                            class="text-500 text-sm"></span></div>
                    <div class="line-height-3 text-700 mb-3"> 
                        Все подводные камни каждой страны - открыто и честно
                    </div>
                    <div class="text-500 flex align-items-center gap-4">
                        <div class="flex align-items-center gap-1">
                            <i class="pi pi-clock"></i>
                            <span class="mr-3">
                                10 мин
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-5 flex">
                <div class="flex flex-column align-items-center" style="width: 2rem;"><span
                        class="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle p-2"><i
                            class="pi pi-flag-fill"></i></span>
                    <div class="h-full bg-yellow-500" style="width: 2px; min-height: 4rem;"></div>
                </div>
                <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                    <div class="mb-3"><span class="text-900 text-yellow-700 font-medium inline-block mr-3">5. Ответы и Вопросы </span><span
                            class="text-500 text-sm"></span></div>
                    <div class="line-height-3 text-700 mb-3"> 
                        Выделенное время для вопросов и ответов по итогам конферении
                    </div>
                    <div class="text-500 flex align-items-center gap-4">
                        <div class="flex align-items-center gap-1">
                            <i class="pi pi-clock"></i>
                            <span class="mr-3">
                                5 мин
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div><!---->
</template>
<template>
    <div class=" px-4 py-5 md:px-6 lg:px-8">
        <div class="grid">
            <div class="col-12 lg:col-4 p-3">
                <div class="shadow-2 surface-card p-3" style="border-radius: 12px;">
                    <div class="flex align-items-center justify-content-between">
                        <div><span class="text-3xl text-900 font-bold">50+</span>
                            <p class="mt-2 mb-0 text-600 text-xl">Успешных сделок</p>
                        </div>
                        <div><img src="/illustration/stats-illustration-4.svg"></div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-4 p-3">
                <div class="shadow-2 surface-card p-3" style="border-radius: 12px;">
                    <div class="flex align-items-center justify-content-between">
                        <div><span class="text-3xl text-900 font-bold">$10 МИЛЛИОНОВ</span>
                            <p class="mt-2 mb-0 text-600 text-xl">Общей суммы сделок</p>
                        </div>
                        <div><img src="/illustration/stats-illustration-5.svg"></div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-4 p-3">
                <div class="shadow-2 surface-card p-3" style="border-radius: 12px;">
                    <div class="flex align-items-center justify-content-between">
                        <div><span class="text-3xl text-900 font-bold">1500+</span>
                            <p class="mt-2 mb-0 text-600 text-xl">Застройщиков по всему миру</p>
                        </div>
                        <div><img src="/illustration/stats-illustration-6.svg"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
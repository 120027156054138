<template>
  <MainCard/>
  <FeaturesList/>
  <Steps/>
  <Stats/>
  <!-- <Feedback/> -->
  <!-- <Blog/> -->
  
  <div class="block-content mt-4">
            <div class="">
                <div class="surface px-4 md:px-6 lg:px-8">
                    <!-- <div class="font-bold text-5xl text-900 mb-5 text-center">Featured Articles</div> -->
                    <div class="grid nogutter">
                      </div>
                      </div>  
                      </div>  
                      </div>
  <div class="flex justify-content-center mb-4">
    <ContactForm/>
  </div>
  <!-- <FAQ/> -->

  <Footer/>
  <div class="home">

<!-- TODO: -->

  </div>
</template>

<script>
import MainCard from '@/components/MainCard.vue'
import FeaturesList from '@/components/FeaturesList.vue'
import Steps from '@/components/Steps.vue'
import ContactForm from '@/components/ContactForm.vue'
import Stats from '@/components/Stats.vue'
// import FAQ from '@/components/FAQ.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'Home',
  components: {
    MainCard,
    FeaturesList,
    Steps,
    ContactForm,
    Stats,
    // FAQ,
    Footer,
  }
}
</script>

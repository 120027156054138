<template>
    <div class="bg-gray-900 px-4 py-8 md:px-6 lg:px-8">
        <div class="grid">
            <div class="col-12 md:col-4 flex flex-wrap justify-content-start lg:mb-0 mb-3">
                <img src="image/logo_white.png" height="84" alt="Image">
            </div>

            <div class="col-12 md:col-4">
                <div class="text-white text-lg mb-4 flex flex-wrap" style="max-width: 290px;">
                    Doğan Araslı Bulvar B10, NO:230 ISTANBUL, TURKEY     
                </div>
                <div class="text-white mb-3"><i class="pi pi-phone border-round p-1 mr-1"></i>+90 (534) 077 11 78 </div>
                <div class="text-white mb-3"><i class="pi pi-inbox border-round p-1 mr-2"></i>becapital.tr@gmail.com</div>
                
            </div>
            <div class="col-12 md:col-4 text-gray-200">
                <div class="text-white font-bold line-height-3 mb-3">Company</div>
                <a class="line-height-3 block cursor-pointer mb-2">About Us</a>
                <!-- <a class="line-height-3 block cursor-pointer mb-2">News</a> -->
                    <!-- <a class="line-height-3 block cursor-pointer mb-2">Investor Relations</a> -->
                    <a class="line-height-3 block cursor-pointer mb-2">Careers</a>
                    <!-- <a class="line-height-3 block cursor-pointer">Media Kit</a> -->
                    <div class="text-white mb-3">
                        <a class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5"
                            style="width: 1.5rem; height: 1.5rem;" href="https://instagram.com/gaukhara_bolat?igshid=MzRlODBiNWFlZA==">
                            <i class="pi pi-instagram"></i>
                        </a>
                        <a class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5"
                            style="width: 1.5rem; height: 1.5rem;" href="https://vuejs.org/">
                            <i class="pi pi-telegram"></i>
                        </a>
                        <a class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center"
                            style="width: 1.5rem; height: 1.5rem;" href="https://wa.me/905340771178">
                            <i class="pi pi-whatsapp"></i>
                        </a>
                    </div>
                    <GooseTeam class=""/>
            </div>
        </div>
    </div>
</template>

<script>
import GooseTeam from '@/components/GooseTeam.vue'

export default {
    components: {
        GooseTeam,
    }
}
</script>